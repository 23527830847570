<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:body>
        <div>
          <div :style="forCulture" v-html="customConsentFormPreviewHtml"></div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="cancel()"> {{ $t('Back') }}</button>
        </div>
      </template>
    </iq-card>
  </b-container>

</template>
<script>

  import consentFormService from '../../../services/customerConsentForm';

  export default {
    name: 'CustomConsentFormPreview',
    components: {},
    props: { customConsentFormId: String },
    data() {
      return {
        customConsentFormPreviewHtml: "",
        forCulture: ""
      }
    },
    methods: {
      cancel() {
        this.$router.go(-1);
      },
      getCustomConsentFormHtml() {
        consentFormService.getCustomConsentFormPreview(this.customConsentFormId)
          .then(response => {
            if (response) {
              this.customConsentFormPreviewHtml = response.html;
            }
            else {
              this.$toastr.error(this.$t("Error"));
            }

            if (this.customConsentFormPreviewHtml.indexOf("direction") !== -1) {
              this.forCulture = "height: 800px !important; overflow: scroll; text-align:right !important; direction: rtl; ";
            }
            else this.forCulture = "height: 800px !important; overflow: scroll;";
          });
      }
    },
    mounted() {
      if (this.customConsentFormId) {
        this.getCustomConsentFormHtml();
      }
    }
  }
</script>
